import React from 'react'
import Table from "react-bootstrap/Table";
import { useParams,useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux/es/exports';
import { useEffect,useState } from 'react';
 import { DLT,ADD,REMOVE } from '../redux/actions/action';


const CardsDetails = () => {

    const [data,setData]= useState([]);
    console.log(data);
    const{id}= useParams();
    //console.log(id);

        const history= useNavigate()

    const dispatch = useDispatch();

    const getdata = useSelector((state)=>state.cartreducer.carts);

    const compare = () =>{
        let comparedata = getdata.filter((e)=>{
            return e.id == id
         });
         setData(comparedata);
    }

   

       const send =(e)=>{
        //console.log(e);
          dispatch(ADD(e));
          }
        

       const dlt = (id)=>{
        dispatch(DLT(id));
        history('/home');
    }

    //remove one

    const remove = (item)=>{
        dispatch(REMOVE(item))
       
    }


    useEffect(() => {
        compare (); 
       }, [id])

  return (
  <>
    <div className="container mt-2">
        <h2 className='text-center'>Items Details Page</h2>

        <section className='container mt-3'>
        <div className="iteamsdetails">
        {
            data.map((eLe)=>{
                return(
                    <>
                     
                    <div className="items_img">
            <img src={eLe.imgdata} alt=''/>
        </div>
        <div>
        <Table>
            <tr>
                <td>
                    <p><strong>Resturant</strong> : {eLe.rname}</p>
                    <p><strong>Price</strong> : ₹ {eLe.price}</p>
                    <p><strong>Dishes</strong> : {eLe.address}</p>
                    <p><strong>Total</strong> : ₹ {eLe.price*eLe.qnty}</p>
                    <div className='mt-5 d-flex justify-content-between align-item-center' style={{width:100, cursor:'pointer',backgroundColor:'#ddd',color:'#111'}}>
                        <span style={{fontSize:24}} onClick={eLe.qnty <=1?()=>dlt(eLe.id): ()=>remove(eLe)}>-</span>
                        <span style={{fontSize:22}}>{eLe.qnty}</span>
                        <span style={{fontSize:24}} onClick={()=>send(eLe)}>+</span>
                    </div>
                </td>
                <td>
                    <p><strong>Rating : </strong><span style={{background:'green',color:'#fff',padding:'2px 5px'}}>{eLe.rating}</span></p>
                    <p><strong>Order Review : </strong><span>{eLe.somedata}</span></p>
                    <p><strong>Remove : </strong><span><i className='fas fa-trash' onClick={()=>dlt(eLe.id)} style={{color:'red',fontSize:18,cursor:'pointer'}}></i></span></p>
                </td>
            </tr>
        </Table>
        </div>
       
                    </>
                )
            })
        }

</div> 
        </section>
    </div>
   </>
  )
}

export default CardsDetails
