import React, { useState } from 'react'
import '../Sign_img.css'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import SIgn_img from './SIgn_img'
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import hidePwdImg from '../assets/hide-password.svg';
import showPwdImg from '../assets/show-password.svg';
//import {  toast } from 'react-toastify';
 // import 'react-toastify/dist/ReactToastify.css';
//import { Password } from '@mui/icons-material'


const Sign_in = () => {

    const [isRevealPwd, setIsRevealPwd] = useState(false);

    const navigate=useNavigate();
    const [inpval,setInpval]=useState({
        email:'',      
        password:''
    });

    const [data,setData]=useState();
  //  console.log(inpval)
    const getdata = (e) => {

        const {value,name}=e.target;
        //console.log(value,name);
        setInpval(()=>{
            return{
                ...inpval,
                [name]:value
            }
        });
    }


    const addData=(e)=>{
        e.preventDefault();
        console.log(inpval);

        const {  email,  password } = inpval;

        const getuserArr= JSON.parse(localStorage.getItem('useryoutube'));

      if (email === "") {
            alert('email field is requred',{
                position: "top-center",
            });
        } else if (!email.includes("@")) {
            alert('plz enter valid email addres',{
                position: "top-center",
            });
        }  else if (password === "") {
            alert('password field is requred',{
                position: "top-center",
            });
        } else if (password.length < 5) {
            alert('password length greater five',{
                position: "top-center",
            });
        } else if(
            getuserArr.find((el,k)=> el.email===email && el.password===password
            )){
                console.log(getuserArr);
            console.log('login successful');
                 navigate('/home');
           
            }
            // if (userlogin.length===0){
            //     alert('Invailed detailed!')
            // }  
            else { alert('login failed');
            
        }
         
        
        
    }
  return (
    <>


<section className="vh-100 ">
  <div className="container-fluid h-custom">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-md-9 col-lg-6 col-xl-5">
        <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
          className="img-fluid" alt="Sample image"/>
      </div>
      <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
        <form>
          <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
            <p className="lead fw-normal mb-0 me-3">Sign in with</p>
            <button type="button" className="btn btn-primary btn-floating mx-1">
              <i className="fab fa-facebook-f"></i>
            </button>

            <button type="button" className="btn btn-primary btn-floating mx-1">
              <i className="fab fa-twitter"></i>
            </button>

            <button type="button" className="btn btn-primary btn-floating mx-1">
              <i className="fab fa-linkedin-in"></i>
            </button>
          </div>

          <div className="divider d-flex align-items-center my-4">
            <p className="text-center fw-bold mx-3 mb-0">Or</p>
          </div>

         
          <div className="form-outline mb-4">
            <input type="email" name='email' onChange={getdata} id="form3Example3" className="form-control form-control-lg"
              placeholder="Enter a valid email address" />
            <label className="form-label" htmlFor="form3Example3">Email address</label>
          </div>

        
          <div className="form-outline mb-3">
            <input type="password" name='password' onChange={getdata} id="form3Example4" className="form-control form-control-lg"
              placeholder="Enter password" />
            <label className="form-label" htmlFor="form3Example4">Password</label>
          </div>

          {/* <div className="d-flex justify-content-between align-items-center">
         
            <div className="form-check mb-0">
              <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
              <label className="form-check-label" htmlFor="form2Example3">
                Remember me
              </label>
            </div>
            <a href="#!" className="text-body">Forgot password?</a>
          </div> */}

          <div className="text-center text-lg-start mt-4 pt-2">
            <button type="button" onClick={addData} className="btn btn-primary btn-lg"
              style={{paddingLeft: "2.5rem", paddingRight: "2.5rem"}}>Login</button>
            <p className="small fw-bold mt-2 pt-1 mb-0">Don't have an account? <a href="/sign_up"
                className="link-danger">Register</a></p>
          </div>

        </form>
      </div>
      <div
    className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
  
    <div className="text-white mb-3 mb-md-0">
      Copyright © 2022. All rights reserved.
    </div>
   

    
    <div>
    <a href="https://www.facebook.com/profile.php?id=100008671355917" className="text-white me-4">
        <i className="fab fa-facebook-f"></i>
      </a>
      <a href="#!" className="text-white me-4">
        <i className="fab fa-twitter"></i>
      </a>
      <a href="#!" className="text-white me-4">
        <i className="fab fa-google"></i>
      </a>
      <a href="#!" className="text-white">
        <i className="fab fa-linkedin-in"></i>
      </a>
    </div>
    
  </div>
    </div>
  </div>
  
</section>
  
    </>
  )
}

export default Sign_in
