import './App.css';
import Header from './Components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Routes,Route} from 'react-router-dom'
import Cards from './Components/Cards'
import CardsDetails from './Components/CardsDetails'
import Signup from './Components/Signup';
import Sign_in from './Components/Sign_in';

function App() {
  return (
    <>

  <Routes>
    <Route path='/' element={<Sign_in/>}/>
    <Route path='/sign_up' element={<Signup/>}/>
    <Route path='/home' element={ <> <Header/><Cards/></>}/>
    <Route path='/cart/:id' element={ <> <Header/><CardsDetails/></>}/>
  </Routes>
  {/* <Routes>
    <Route path='/' element={<Cards/>}/>
    <Route path='/cart/:id' element={<CardsDetails/>}/>
  </Routes> */}
    </>
  );
}

export default App;
